import React from 'react';
import logo from './logo1.png';
import Constants from './Constants';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <a href={Constants.APP_EMAIL}>{Constants.APP_EMAIL}</a>
      </header>
    </div>
  );
}

export default App;
